// notifications/actions.js

// Импорт функции обработчика сообщений
import { handleMessages } from './handlers';
import chatAPI from "@/service/API/chatAPI";

// Экспорт действия для обработки сообщений WebSocket
export const handleNotificationsMessages = async ({commit, dispatch}, data) => {
    await handleMessages({commit, dispatch}, data);
};
// Экспорт действия для обработки сообщений WebSocket
export const removeNotifications = ({ commit }) => {
  commit('removeNotifications');
};

export const loadUnreadMessageList = async ({commit}) => {
    const response = await chatAPI.getUnreadMessage()
    commit('setUnreadMessageList', response.data);
    commit('initializeUnreadMessageListState', true);
};

export const loadUnreadMessageCount = async ({commit}) => {
    const response = await chatAPI.getUnreadMessageCount()
    commit('setUnreadMessageCount', response.data.total);
};

export const setNotification = async ({commit}, notification) => {
    commit('setNotification', notification);
};
export const updateUnreadMessageList = async ({commit}, {orderId, chatName}) => {
    commit('updateUnreadMessageList', {orderId, chatName});
};
