// globalChat/actions.js

// Импорт функции обработчика сообщений
import { addMessages} from "@/utils/orderChat";
import chatAPI from "@/service/API/chatAPI";
import {handleGlobalMessages} from "@/store/modules/globalChat/handlers";

// Экспорт действия для обработки сообщений WebSocket
export const handleChatMessages = ({ commit,rootGetters,state }, message) => {
  handleGlobalMessages(commit,rootGetters , message,state);
};

export const joinOrderRoom = async ({commit, dispatch}, orderId) => {
    commit('setCurrentOrderId', orderId);
    dispatch('websocket/sendMessage', {
        type: 'chat',
        scope_type: 'order',
        action: 'join_order',
        order_id: orderId
    }, {root: true}); // Указываем, что действие находится в корневом уровне
    commit('setIsConnectedRoom', true); // Обновляем флаг подключения к комнате
};

export const loadGlobalChats= async ({commit}) => {
    commit('setInitializeChatsState', true);
    const response = await chatAPI.getGlobalChats()
    commit('setChats', response.data);

};

export const leaveOrderRoom = ({ commit, dispatch }) => {
    dispatch('websocket/sendMessage', {
        type: 'chat',
      scope_type: 'order',
      action: 'leave_order'
    }, { root: true }); // Действие находится в корневом уровне
    commit('setCurrentOrderId', null); // Сбрасываем текущий ID заказа
    commit('setIsConnectedRoom', false);
    commit('setSelectedChat', null)
    commit('setChats', {});
    commit('setLoadingChatState', false);
    commit('setInitializeChatsState', false);
};

export const sendChatMessage = ({ dispatch },data) => {
    dispatch('websocket/sendMessage', {
        scope_type: 'global_chat',
        type: "chat",
        action: "send_message",
        message: data
      }, { root: true }); // Действие находится в корневом уровне
};
export const editChatMessage = ({ dispatch },data) => {
    dispatch('websocket/sendMessage', {
        scope_type: 'global_chat',
        type: "chat",
        action: "edit_message",
        data: data,
      }, { root: true }); // Действие находится в корневом уровне
};
export const updateLastReadMessage = ({  dispatch }, lastReadMessage)=> {
    dispatch('websocket/sendMessage', {
        scope_type: 'global_chat',
        type: "chat",
        action: "update_last_read_message",
        last_read_message: lastReadMessage.id,
    }, { root: true }); // Действие находится в корневом уровне
};

export const removeMessage = ({  dispatch }, messageId)=> {
    dispatch('websocket/sendMessage', {
      scope_type: 'global_chat',
      type: "chat",
      action: "remove_message",
      remove_message: messageId,
    }, { root: true }); // Действие находится в корневом уровне
};
export const printMessage = ({  dispatch }, {printState,selectedChat}) => {
    dispatch('websocket/sendMessage', {
        scope_type: 'global_chat',
        type: "chat",
        action: "change_print_message_state",
        selected_chat: selectedChat,
        print_state: printState,
        order_id: null
    }, { root: true }); // Действие находится в корневом уровне
};

export const fetchMessages = async ({state,rootGetters,commit},chatId) => {
    try {
        const response = await chatAPI.getChatMessages(chatId);
        const userId = rootGetters['auth/user'].id;
        const chat_name = state.selectedChat
        const messages = addMessages([], response.data, userId)
        commit('setMessages', {chat_name, messages})
    } catch (error) {
      console.error('Failed to fetch messages:', error);
    }
};

export const changeSelectedChat = async ({state,rootGetters,commit},chatName) => {
    const chat = state.chats[chatName];
    commit('setSelectedChat', chatName);

    if (chat && !chat.messages) {
        const chatId = chat.id;
        commit('setLoadingChatState', true);
        await fetchMessages({state,rootGetters,commit},chatId);
        commit('setLoadingChatState', false);
    }
};
