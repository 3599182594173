// store/modules/orderCompany.js
import companyAPI from "@/service/API/companyAPI";

const state = {
  company: null,
  payers: [],
  orders: [],
  contacts: [],
  companyId: null, // Добавляем companyId
  uploaded: false,
  uploadedPayers: false,
  uploadedOrders: false,
  uploadedContacts: false,
  error: null,
};

const getters = {
  // Геттер для получения текущего заказа
  uploaded: (state) => state.uploaded,
  uploadedPayers: (state) => state.uploadedPayers,
  uploadedOrders: (state) => state.uploadedOrders,
  uploadedContacts: (state) => state.uploadedContacts,
  contacts: (state) => state.contacts,
  company: (state) => state.company,
  orders: (state) => state.orders,
  payers: (state) => state.payers,
  error: (state) => state.error,
  // Геттеры для других свойств по необходимости
};

const actions = {
  async fetchCompany({ commit }, companyId) {
    try {
      const response = await companyAPI.getCompany(companyId);
      commit('setCompanyId', companyId); // Сохраняем companyId
      commit('setCompany', response.data); // Обновляем текущий заказ
      commit('setUploaded', true);
    } catch (error) {
      console.error('Ошибка при получении компании:', error);
      commit('setError', error);
    }
  },
  async fetchPayers({ commit },companyId) {
    try {
      const response = await companyAPI.getPayers(companyId);
      commit('setPayers', response.data); // Обновляем контрагентов
      commit('setUploadedPayers', true);
      commit('setError', null);
    } catch (error) {
      console.error('Ошибка при получении контрагентов:', error);
      commit('setError', error);
    }
  },
  async fetchOrders({ state, commit }) {
    try {
      const response = await companyAPI.getOrders(state.companyId);
      commit('setOrders', response.data); // Обновляем контрагентов
      commit('setUploadedOrders', true);
      commit('setError', null);
    } catch (error) {
      console.error('Ошибка при получении контрагентов:', error);
      commit('setError', error);
    }
  },
  async fetchContacts({ state, commit }) {
    try {
      const response = await companyAPI.getContacts(state.companyId);
      commit('setContacts', response.data); // Обновляем контрагентов
      commit('setUploadedContacts', true);
      commit('setError', null);
    } catch (error) {
      console.error('Ошибка при получении контрагентов:', error);
      commit('setError', error);
    }
  },
  async updateCompany({ commit }, data) {
    try {
      commit('setCompany', data); // Обновляем текущий заказ
      commit('setError', null);
    } catch (error) {
      console.error('Ошибка при обновлении компании:', error);
      commit('setError', error);
    }
  },
  async resetOrderStore({ commit }) {
    commit('setCompany', null);
    commit('setContacts', []);
    commit('setPayers', []);
    commit('setOrders', []);
    commit('setCompanyId', null); // Сбрасываем companyId
    commit('setUploaded', false);
    commit('setUploadedPayers', false);
    commit('setUploadedOrders', false);
    commit('setUploadedContacts', false);
    commit('setError', null);
    // Сброс других частей состояния по необходимости
  }
};

const mutations = {
  setCompany(state, company) {
    state.company = company; // Устанавливаем текущий заказ
  },
  setCompanyId(state, companyId) {
    state.companyId = companyId; // Устанавливаем companyId
  },
  setUploaded(state, uploaded) {
    state.uploaded = uploaded;
  },
  setUploadedPayers(state, uploaded) {
    state.uploadedPayers = uploaded;
  },
  setUploadedOrders(state, uploaded) {
    state.uploadedOrders = uploaded;
  },
  setUploadedContacts(state, uploaded) {
    state.uploadedContacts = uploaded;
  },
  setPayers(state, payers) {
    console.log('setPayers', payers);
    state.payers = payers; // Устанавливаем контрагентов
  },
  setContacts(state, contacts) {
    state.contacts = contacts; // Устанавливаем контрагентов
  },
  setOrders(state, orders) {
    state.orders = orders; // Устанавливаем контрагентов
  },
  setError(state, error) {
    state.error = error;
  },
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};