// store/modules/dashboardFeed.js
import orderAPI from "@/service/API/orderAPI";
import { getSettings } from "@/utils/localStorageUtils";

const state = {
    feeds: [],
    count: 0,
    initFeed: true,
    loading: false,
    queryParams: {
        page:1,
        page_size:20,
        period:5,
        category:'',
    },
    initialized: false,
    error: null,
};
function parseQueryParams(queryParams) {
    console.log('settings parseQueryParams',queryParams)
    const category = queryParams.category?.split(',') || [];
    const settings = {
        ...queryParams,
        category: category ,


    };

    console.log('settings',settings)
    return settings;
}
const getters = {
    feeds: (state) => state.feeds,
    count: (state) => state.count,
    loading: (state) => state.loading,
    queryParams: (state) => state.queryParams,
    initialized: (state) => state.initialized,
    initFeed: (state) => state.initFeed,
    error: (state) => state.error,
    settingsFeed: (state)  => parseQueryParams(state.queryParams)
};

function createQueryParams(settings={}) {
    const params = {};
    // Пример значений по умолчанию
    params.page = 1
    params.page_size = 10
    params.period = settings?.period || 1;
    params.category = settings?.category.join(',');

    console.log('settings params',params)

    // Пример значения по умолчанию для kp
    return params;
}

const actions = {
  async fetchFeed({ commit, state }) {
    commit("setLoading", true);
    try {
        const response = await orderAPI.getOrderAggregateList(state.queryParams);
      commit("setFeeds", response.data.results);
      commit("setCount", response.data.count);
      commit("setInitFeed", true);
    } catch (error) {
      console.error("Ошибка при получении заказов:", error);
      commit("setError", error);
    } finally {
      commit("setLoading", false);
    }
  },

  async initSettings({ commit,dispatch}) {
      let settingsFeed = getSettings('dashboard','feedBlock');
      if (!settingsFeed) {
          settingsFeed = {};
          settingsFeed.category = ['contracts','history','messages','files'];
      }
      commit("setQueryParams",createQueryParams(settingsFeed));
      console.log('settings queryParams',state.queryParams)

      commit("setInitialized", true);
      await dispatch("fetchFeed");
  },

  // async updateOrdersCount({ commit,state }) {
  //     console.log("updateOrdersCount");
  //     const response = await orderAPI.getOrdersCount(state.queryParams);
  //     commit("setCount", response.data.total_count);
  //     commit("setInitOrders", false);
  //
  // },
};

const mutations = {
  setFeeds(state, feeds) {
    state.feeds = feeds;
  },
  setInitFeed(state, initFeed) {
    state.initFeed = initFeed;
  },
  setCount(state, count) {
    state.count = count;
  },
  setLoading(state, loading) {
    state.loading = loading;
  },
  setQueryParams(state, queryParams) {
    state.queryParams = queryParams;
  },
  setInitialized(state, initialized) {
    state.initialized = initialized;
  },
  setError(state, error) {
    state.error = error;
  },
};

// const subscriptions = (store) => {
//   store.subscribe((mutation) => {
//     if (mutation.type === "dashboardOrders/setQueryParams") {
//       store.dispatch("dashboardOrders/updateOrdersCount");
//     }
//   });
// };

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};