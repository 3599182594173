// store/modules/persons.js
import personAPI from "@/service/API/personAPI";

const state = {
  personsList: [],
  positionsList: [],
  uploaded: false,
  error: null,
};

const getters = {
  personsList: state => state.personsList,
  positionsList: state => state.positionsList,
  uploaded: state => state.uploaded,
  error: state => state.error,
};

const actions = {
  async fetchPersonsList({ commit }) {

    try {
      console.log('fetchPersonsList')
      const response = await personAPI.getPersonsList();
      commit('setPersonsList', response.data);
      commit('setError', null);
    } catch (error) {
      console.error('Ошибка при получении списка статусов заказа:', error);
      commit('setError', error);
    } finally {
      commit('setUploaded', true);
    }
  },
  async fetchPositionsList({ commit }) {
    try {
      console.log('fetchPersonsList')
      const response = await personAPI.getPositionsList();
      commit('setPositionsList', response.data);
      commit('setError', null);
    } catch (error) {
      console.error('Ошибка при получении списка должностей:', error);
      commit('setError', error);
    } finally {
      commit('setUploaded', true);
    }
  },
  async personUpdate({ commit },data) {
    try {
      console.log('personUpdate',data)
      const result = await personAPI.savePerson(data);
      console.log('personUpdate result',result)
      const index = state.personsList.findIndex(person => person.id === result.data.id);
      if (index !== -1) {
        commit('updatePersonAtIndex', { index, data: result.data });
        return {success:true,detail:'Сотрудник изменен'}
      } else {
        commit('addPerson', result.data);
        return {success:true,detail:'Сотрудник добавлен'}
      }
    } catch (error) {
      console.error('Ошибка при обновлении товара:', error);
      commit('setError', error);
      return {success:false,detail:error.data}
    }
  },
  async positionUpdate({ commit },data) {
    try {
      console.log('positionUpdate',data)
      const result = await personAPI.savePosition(data);
      console.log('positionUpdate result',result)
      const index = state.positionsList.findIndex(position=> position.id === result.data.id);
      if (index !== -1) {
        commit('updatePositionAtIndex', { index, data: result.data });
        return {success:true,detail:'Должность изменена'}
      } else {
        commit('addPosition', result.data);
        return {success:true,detail:'Должность добавлена'}
      }
    } catch (error) {
      console.error('Ошибка при обновлении товара:', error);
      commit('setError', error);
      return {success:false,detail:error.data}
    }
  }
};

const mutations = {
  setPersonsList(state, personsList) {
    state.personsList = personsList;
  },
  setPositionsList(state, positionsList) {
    state.positionsList = positionsList;
  },
  updatePersonAtIndex(state, { index, data }){
    state.personsList.splice(index, 1, data);
  },

  addPerson(state, person) {
    state.positionsList.push(person);
  },
  updatePositionAtIndex(state, { index, data }){
    state.positionsList.splice(index, 1, data);
  },
  addPosition(state, position)  {
    state.positionsList.push(position);
  },
  setUploaded(state, uploaded) {
    state.uploaded = uploaded;
  },
  setError(state, error) {
    state.error = error;
  },
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};