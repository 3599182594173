<script setup>

import ChatMessage from "@/components/OrderPage/Blocks/ChatMessage.vue";
import router from "@/router";
import {FontAwesomeIcon} from "@fortawesome/vue-fontawesome";

const toOrder = (id) => {

  router.push({
    name:'Order',
    params:{
      orderId:id
    }
  })
  clear();
}
import { useToast } from "primevue/usetoast";
const toast = useToast();
const clear = () => {
    toast.removeAllGroups();
}
</script>

<template>
<Toast position="top-right" group="notification" >
    <template #message="slotProps" >
        <div class="flex flex-col align-items-start" style="flex: 1">
            <div class="flex align-items-center gap-1">
                <Avatar image="/image/new_mail.png" shape="circle" />
                <span class="font-bold ">{{ slotProps.message.summary }}</span>
            </div>
            <div class="flex p-2 bg-black rounded-md">
              <ChatMessage :message="slotProps.message.data" />
            </div>
            <div v-if="slotProps.message.data.order"
                 class="flex justify-end font-bold text-blue-700 gap-3 cursor-pointer hover:text-blue-500 items-center"
                 @click="toOrder(slotProps.message.data.order)">
              <span v-text="'Заказ #'+ slotProps.message.data.order"/>
              <font-awesome-icon icon="arrow-right" />
            </div>
        </div>
    </template>
</Toast>
</template>

<style scoped>

</style>