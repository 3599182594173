import API from "@/service/API/API";
const url_pref = '/api/v1'
export default {
    getChatOrderMessageList(order_id,last_update_id=0){
        console.log('getTaskList')
        return API().get(`${url_pref}/chat-message/?order_id=${order_id}&last_update_id=${last_update_id}`)
    },
    getChatMessage(id){
        console.log('getChatMessage')
        return API().get(`${url_pref}/chat-message/${id}`)
    },
    getChatMessages(chatId,params={}){
        console.log('getChatMessage')
        return API().get(`${url_pref}/chat/${chatId}/messages/`, {params})
    },
    getUnreadMessage(){
        console.log('getUnreadMessage')
        return API().get(`${url_pref}/chat-message/unread_messages/`)
    },
    getUnreadMessageCount(){
        console.log('getUnreadMessageCount')
        return API().get(`${url_pref}/chat-message/unread_messages_count/`)
    },
    getGlobalChats(){
        console.log('getGlobalChats')
        return API().get(`${url_pref}/chat-message/global_chats/`)
    },

    addChatOrderMessage(data){
        return API().post((`${url_pref}/chat-message/`), data)
            .then(
                function (response) {
                  return response
                }
            )
            .catch(
                function (error) {
                  console.log('Show error notification!')
                  console.log(error.response)
                  return error.response
                }
            ).finally()

    }


}