<script setup>
// import moment from 'moment'

// eslint-disable-next-line no-undef
import {FontAwesomeIcon} from "@fortawesome/vue-fontawesome";
import {useWebSocket} from "@/composables/useWebSocket";
import {computed, ref} from "vue";
import ReplyMessageCard from "@/components/OrderPage/Chats/ReplyMessageCard.vue";
import {fileExtension} from "@/utils/fileExtension";

// eslint-disable-next-line no-undef
const props = defineProps({
  message:Object,
  selectedMessage:{
    type:Boolean,
    default:false
  },
  isPersonalChat:{
    type:Boolean,
    default:false
  },
  isEditedMessage:{
    type:Boolean,
    default:false
  },
  baseUrl:{
    type:String,
    default: ''
  }
})

// eslint-disable-next-line no-undef
const emit = defineEmits(['removeMessage','editMessage','replyMessage','goToReply'])
const {allUsers} = useWebSocket()
const file = props.message.files?.length ? props.message.files[0].file : null;
const thumbnail = props.message.files?.length ? props.message.files[0]?.thumbnail : null;
const fileHref = file?.startsWith(props.baseUrl)?file:props.baseUrl+file
const fileHrefThumbnail = file?.startsWith(props.baseUrl)?thumbnail:props.baseUrl+thumbnail
const is_image = file ? ['jpg', 'jpeg', 'png', 'gif', 'webp'].includes(fileExtension(file)) : false;
function getFileIcon() {
  const extension = file.split('.').pop().toLowerCase();

  switch (extension) {
    case 'doc':
    case 'docx':
      return '/image/docx-icon.png';
    case 'xls':
    case 'xlsx':
      return '/image/excel-icon.png';
    case 'pdf':
      return '/image/pdf-icon.png';
    default:
      return '/image/file-icon.png';
  }
}

const itemsMyMessage = ref([
    {
        label: 'Ответить',
        show: true,
        icon: 'pi pi-reply',
        command: () => {
                    emit('replyMessage', props.message)
                }

    },
    {
        label: 'Редактировать',
        show:props.message.from_me === true,
        icon: 'pi pi-pencil',
        command: () => {
                    emit('editMessage', props.message)
                }

    },
    {
        label: 'Удалить',
        show:props.message.from_me === true,
        icon: 'pi pi-trash',
        command: () => {
          emit('removeMessage', props.message.id)
        }
    }
]);
const menu = ref();
const items = ref(null);


const contextmenuShow = (event) => {
  if(props.isEditedMessage) {
    items.value = itemsMyMessage.value.filter(item => item.show === true)
     menu.value.show(event)
  }


}
const formatMessageWithEmojiSize = (message, size='30px') => {
  return message.replace(/([\uD800-\uDBFF][\uDC00-\uDFFF])/g, `<span style="font-size: ${size};">$1</span>`);
};
const creatorName = computed(() => {
  return allUsers.value[props.message.creator]?.name || ''
})
const recipientsByTooltip = computed(() => {
  return props.message.recipient
    .map((person) => {
      const name = allUsers.value[person]?.name || '';
      const isReader = props.message.readers.includes(person);
      const checkMark = isReader ? '✔' : '';
      return `
           <div class="text-nowrap w-full flex justify-between">
             <span>${name}</span>${checkMark}
           </div>`;
    })
    .join('<br>');
});</script>

<template>
  <ContextMenu ref="menu" :model="items"  >
  </ContextMenu>
  <div v-if="message.isDateHeader" :data-id="message.id" class="date-header flex justify-center">
    <div class="w-1/3 py-1  rounded-md bg-blue-200 text-center text-nowrap text-red-600 text-xl"
         v-text="message.dateCreate || 'Дата отсутствует'"
    />
  </div>
<div v-else
     class="w-full animate-flip-up animate-once animate-duration-[1000ms] animate-ease-in-out animate-normal animate-fill-forwards"
>
  <div v-if="!message.from_me && !message.resultMessage && !isPersonalChat" class="text-left text-red-400 text-xs "
       v-text="creatorName"
  />

<div class="flex w-full"
     :id="`message-${message.id}`"
     :class="{
         'justify-end': message.from_me === true,
         'justify-start': message.from_me === false,
         'justify-center': message.from_me === undefined
     }">

  <div :class="{'w-36':message.from_me!==false}" class="flex"></div>
  <div class="flex flex-col whitespace-pre-wrap rounded-md p-2 m-1 message "

     @contextmenu="message.status !== 'deleted' && contextmenuShow($event)"
       :class="{
         'bg-yellow-100':selectedMessage,
         'bg-green-100': message.from_me === true,
         'bg-purple-100': message.from_me === false,
         'bg-gray-100': message.from_me === undefined
       }"
  >
        <ReplyMessageCard v-if="message.reply_message" :replyMessage="message.reply_message"
                          @goToReply="emit('goToReply', message.reply_message.id)"
 />

<!--    <Image v-if="message.files.length && message.status!=='deleted'"-->
<!--           alt="Image" preview class="px-1 h-[100px] bg-white flex flex-row justify-center"-->
<!--            width="100"-->
<!--           :src="message.files[0].file.startsWith(baseUrl)?message.files[0].file:baseUrl+message.files[0].file"-->
<!--           :pt="{-->
<!--                 rotateRightButton: { class: 'bg-primary-200' },-->
<!--                 rotateLeftButton: { class: 'bg-primary-200' },-->
<!--                 zoomOutButton: { class: 'bg-yellow-200' },-->
<!--                 zoomInButton: { class: 'bg-yellow-200' },-->
<!--                 closeButton: { class: 'bg-red-200' },-->
<!--                 toolbar: { class: 'gap-x-1.5' },-->
<!--           }"-->
<!--    />-->

    <div class="flex flex-col w-auto relative" v-if="file && message.status!=='deleted'">
      <Image alt="Image"
             :preview="is_image"
             class="px-1 flex flex-col justify-center cursor-pointer"
             :src="is_image?fileHref:getFileIcon()"
             :pt="{
                      image: { class: 'h-16 w-auto self-center' },
                      rotateRightButton: { class: 'bg-primary-200' },
                      rotateLeftButton: { class: 'bg-primary-200' },
                      zoomOutButton: { class: 'bg-yellow-200' },
                      zoomInButton: { class: 'bg-yellow-200' },
                      closeButton: { class: 'bg-red-200' },
                      toolbar: { class: 'gap-x-1.5' },
                    }"
      >
        <template #image >
          <img :src="is_image?fileHrefThumbnail:getFileIcon()"
               :class="is_image?'h-48 w-auto object-contain':'h-24 w-auto object-contain'"
          />
        </template>
      </Image>
      <a
          v-if="!is_image"
          :href="fileHref" target="_blank"
          class="absolute flex justify-center items-center self-center w-full bg-gray-500/10 h-full opacity-0 hover:opacity-100"
      >
        <font-awesome-icon icon="fa-regular fa-circle-down" size="2x" class="p-1"/>
      </a>
    </div>

    <div v-if="message.status!=='deleted'">
      <div class="relative -top-1" v-if="message.from_me === false"
        v-tooltip="{ value: recipientsByTooltip,escape:false

      }"    >
        <font-awesome-icon :icon="(message.recipient.length===1)?'fa-solid fa-user':'fa-solid fa-user-group' "
                           class="absolute -top-2 right-1"
                           :class="{'text-red-600': message.for_me}"
                           v-if="message.recipient.length"
        />
      </div>
      <div class="relative -top-1" v-else
        v-tooltip.left="{ value: recipientsByTooltip ,escape:false}"    >
        <font-awesome-icon :icon="(message.recipient.length===1)?'fa-solid fa-user':'fa-solid fa-user-group' "
                           class="absolute -top-2 right-1"
                           :class="{'text-red-600': message.for_me}"
                           v-if="message.recipient.length"
        />
      </div>
    </div>
    <div class="flex flex-row justify-end " >
      <div :class="{'text-red-600':message.status==='deleted'}" class="flex  message-text break-all"
           style="word-wrap: break-word;"
           v-html="formatMessageWithEmojiSize(message.status==='deleted'?'Сообщение удалено':message.text)"
      />

      <div class="relative flex text-nowrap place-self-end text-xs text-gray-400 top-2 gap-1 "
            v-if="message.from_me!==undefined"
      >
        <span v-if="message.status==='edited'" v-text="message.status==='edited'?'Изменено':''" class="text-red-500"/>
        <span v-text="new Date(message.date_create).toLocaleTimeString('ru-RU',{hour: '2-digit', minute:'2-digit'})"/>
        <span v-tooltip.left="{ value: 'Сообщение прочитано',hideDelay:500,showDelay:300}"
              class="cursor-default"
             v-if="message.from_me && message.status!=='deleted' &&
             (message.is_read || message.recipient.length && message.readers.length=== message.recipient.length)"
        >✔</span>

      </div>
    </div>
  </div>
  <div :class="{'w-36':message.from_me!==true}"></div>
  </div>

</div>
</template>

<style scoped>
.date-header {
  position: relative;
}

.date-header::before,
.date-header::after {
  content: '';
  position: absolute;
  top: 50%;
  width: calc(100%/3);
  height: 3px; /* Высота разделителя */
  //background: linear-gradient(to right, #1c20fa, #1c20fa, transparent);
}


.date-header::before {
  left: 0;
  background: linear-gradient(to right, transparent, #1c20fa, #1c20fa);
}

.date-header::after {
  right: 0;
    background: linear-gradient(to right, #1c20fa, #1c20fa, transparent);

}
@keyframes fadeOut {
  0% { background-color: #2832fa; }
  50% { background-color: #2832fa; }
  100% { background-color: inherit; }
}

.fade-out {
  animation: fadeOut 5s forwards;
}
</style>