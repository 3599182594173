<script setup>
import {useWebSocket} from "@/composables/useWebSocket";
import {useUser} from "@/composables/useUser";
import {computed} from "vue";

// eslint-disable-next-line no-undef
const props =defineProps({
  replyMessage: Object,
  closed:{
    type: Boolean,
    default: false
  }
})

// eslint-disable-next-line no-undef
const emit = defineEmits(['close','goToReply'])
const {allUsers} = useWebSocket()
const { userId } = useUser();
const myMessage = computed(() => {return props.replyMessage.creator === userId.value})

const color = computed(() => {return myMessage.value ? "green" : "red"})
const formatMessageWithEmojiSize = (message, size='2rem') => {
  return message.replace(/([\uD800-\uDBFF][\uDC00-\uDFFF])/g, `<span style="font-size: ${size};">$1</span>`);
};
const creatorName = computed(() => {
  return allUsers.value[props.replyMessage.creator]?.name || ''
})
</script>

<template>
<div class="flex ">
  <div class="w-2 rounded-s-md" :class="`bg-${color}-500`"/>
  <div class="w-full bg-gray-200 flex flex-col p-3 ">
    <span :class="`text-${color}-500`"
          v-text="myMessage?'Вы':creatorName"/>
    <span class="break-all" v-html="formatMessageWithEmojiSize(replyMessage.text)"/>
  </div>
  <div class="bg-gray-200 rounded-e-md p-1">
    <i v-if="closed" class="pi pi-times text-red-600  cursor-pointer" @click="emit('close')" />
    <i v-else class="pi pi-reply text-blue-600  cursor-pointer" @click="emit('goToReply')" />
  </div>
</div>

</template>

<style scoped>

</style>