// store/modules/dashboardContracts.js
import { getSettings } from "@/utils/localStorageUtils";
import contractAPI from "@/service/API/contractAPI";

const state = {
  contracts: [],
  initContracts: true,
  count: 0,
  loading: false,
  queryParams: {},
  initialized: false,
  error: null,
};
function parseQueryParams(queryParams) {
    const settings = {
        filterField: [],
        sortField: [],
        ...queryParams,
    };
    for (const key in queryParams) {
        if (key === 'ordering') {
            const sortFields = queryParams[key].split(',');
            sortFields.forEach(field => {
                const order = field.startsWith('-') ? -1 : 1;
                const fieldName = field.startsWith('-') ? field.slice(1) : field;
                settings.sortField.push({ field: fieldName, order: order });
            });
        } else if (key !== 'page' && key !== 'page_size' ) {
            const valueArray = queryParams[key].split(',');
            const isNumericArray = valueArray.every(item => !isNaN(item));
            settings.filterField.push({
                field: key,
                value: isNumericArray ? valueArray.map(Number) : valueArray
            });
        }
    }

    return settings;
}
const getters = {
  contracts: (state) => state.contracts,
  initContracts: (state) => state.initContracts,
  count: (state) => state.count,
  loading: (state) => state.loading,
  queryParams: (state) => state.queryParams,
  initialized: (state) => state.initialized,
  error: (state) => state.error,
  settingsContracts: (state)  => parseQueryParams(state.queryParams)
};

function createQueryParams(settings) {
    const params = {};
    // Пример значений по умолчанию
    params.page = 1;
    params.page_size = 10;
    if (settings.filterField) {
        settings.filterField.forEach(filter => {
            params[filter.field] = filter.value.join(',');
        });
    }
    if (settings.sortField) {
        if (settings.sortField.length) {
            params.ordering = settings.sortField.map(sort => {
                return `${sort.order === -1 ? '-' : ''}${sort.field}`;
            }).join(',');
        }
    }
    // Пример значения по умолчанию для kp
    return params;
}

const actions = {
  async fetchContracts({ commit, state }) {
    commit("setLoading", true);
    try {
      const response = await contractAPI.getContractsList(state.queryParams);
      commit("setContracts", response.data.results);
      commit("setCount", response.data.count);
      commit("setInitContracts", true);
    } catch (error) {
      console.error("Ошибка при получении заказов:", error);
      commit("setError", error);
    } finally {
      commit("setLoading", false);
    }
  },

  async initSettings({ commit }) {
    const settingsOrders = getSettings("dashboard", "contractsBlock");
    const queryParamsOrders = settingsOrders
      ? createQueryParams(settingsOrders)
      : {};
    // const response = await contractAPI.getContractsCount(queryParamsOrders);
    commit("setQueryParams",queryParamsOrders);
    commit("setInitialized", true);
    // await dispatch("updateContractsCount");

  },
  async updateContractsCount({ commit,state }) {
    console.log("updateContractsCount");
    const response = await contractAPI.getContractsCount(state.queryParams);
    commit("setCount", response.data.total_count);
    commit("setInitContracts", false);

  },
};

const mutations = {
  setContracts(state, contracts) {
    state.contracts = contracts;
  },
  setInitContracts(state, initContracts) {
    state.initContracts = initContracts;
  },
  setCount(state, count) {
    state.count = count;
    state.initContracts = false;
  },
  setLoading(state, loading) {
    state.loading = loading;
  },
  setQueryParams(state, queryParams) {
    state.queryParams = queryParams;
  },
  setInitialized(state, initialized) {
    state.initialized = initialized;
  },
  setError(state, error) {
    state.error = error;
  },
};

// const subscriptions = (store) => {
//   store.subscribe((mutation) => {
//
//     if (mutation.type === "dashboardContracts/setQueryParams") {
//         console.log('subscriptions setQueryParams');
//       store.dispatch("dashboardContracts/fetchContracts");
//     }
//   });
// };

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
  // subscriptions,
};