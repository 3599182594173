import API from "@/service/API/API";
const url_pref = '/api/v1'
export default {
    getFilesOrderList(order_id,type){
        console.log('getFilesOrderList')
        return API().get(`${url_pref}/upload/?order=${order_id}&type=${type}`)
    },
    // getTask(id){
    //     return API().get(`${url_pref}/task/${id}/`)
    // },
    addFile(formData){
        return API().post(`${url_pref}/upload/`,
            formData,
            { headers: {
                    'Content-Type': 'multipart/form-data'
            }})
            .then(function(response){
                return response
            })
            .catch(function(error){
                  console.log('Show error notification!')
                  console.log(error.response)
                  return error.response
            });
    },
    deleteFile(fileId){
        return API().delete(`${url_pref}/upload/${fileId}/`)
            .then(function(response){
                return response
            })
            .catch(function(error){
                  console.log('Show error notification!')
                  console.log(error.response)
                  return error.response
            });
    },

}