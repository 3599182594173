// localStorageUtils.js

/**
 * Сохраняет настройки для указанной страницы.
 * @param {string} page - Ключ для страницы.
 * @param {object} settings - Настройки для сохранения.
 */
export function saveSettings(page, settings) {
    const currentSettings = JSON.parse(localStorage.getItem(page)) || {};
    const updatedSettings = { ...currentSettings, ...settings };
    localStorage.setItem(page, JSON.stringify(updatedSettings));
}

/**
 * Извлекает настройки для указанной страницы.
 * @param {string} page - Ключ для страницы.
 * @param {string} block - Ключ для блока.
 * @returns {object} - Настройки страницы или блока страницы.
 */
export function getSettings(page,block) {
    const settings = JSON.parse(localStorage.getItem(page)) || {}
    if (block && settings[block]){
        return settings[block];
    }else {
        return null;
    }

}