// store/modules/order.js
import orderAPI from "@/service/API/orderAPI";

const state = {
  currentOrder: null,
  uploaded: false,
  error: null,
};

const getters = {
  // Геттер для получения текущего заказа
  currentOrder: (state) => state.currentOrder,
  uploaded: (state) => state.uploaded,
  error: (state) => state.error,
  // Геттеры для других свойств по необходимости
};

const actions = {
  async fetchOrder({ commit }, orderId) {
    try {
      const response = await orderAPI.getOrder(orderId);
      commit('setCurrentOrder', response.data); // Обновляем текущий заказ
      commit('setUploaded', true);
    } catch (error) {
      console.error('Ошибка при получении заказа:', error);
      commit('setError', error);
    }
  },
  async saveOrderRequest({ commit }, data) {
    try {
      const response = await orderAPI.saveOrderRequest(data);
      const requestOrder = response.data;
      let detail;
      const index = state.currentOrder.requests.findIndex(req => req.id === requestOrder.id);
      if (index !== -1) {
        detail = 'Сообщение обновлен';
      } else {
        detail = 'Сообщение добавлено';
      }
      commit('saveRequestOrder', {requestOrder,index}); // Обновляем текущий заказ
      return { success: true ,detail: detail };
    } catch (error) {
      console.error('Ошибка при сохранении запроса ордера:', error);
      commit('setError', error);
      return { success: false, error };
    }
  },
  async toggleExecutor({ commit }, orderId) {
    try {
      const response = await orderAPI.toggleExecutor(orderId);
      commit('setUpdateExecutorsOrder', response.data); // Обновляем текущий заказ
    } catch (error) {
      console.error('Ошибка при получении заказа:', error);
      commit('setError', error);
    }
  },
  async updateOrder({ commit }, { orderId, data }) {
    try {
      const updatedOrder = await orderAPI.editOrder(orderId, data);
      commit('updateOrder', updatedOrder.data);
      return { success: true };
    } catch (error) {
      console.error("Ошибка при обновлении ордера:", error);
      return { success: false, error };
    }
  },
  async resetOrderStore({ commit }) {
    commit('setCurrentOrder', null);
    commit('setUploaded', false);
    commit('setError', null);
    // Сброс других частей состояния по необходимости
  }
  // Дополнительные действия по необходимости
};

const mutations = {
  // Мутация для обновления текущего заказа
  setCurrentOrder(state, orderData) {
    state.currentOrder = orderData; // Устанавливаем текущий заказ
  },
  // Мутация для обновления executors текущего заказа
  setUpdateExecutorsOrder(state, executors) {
    state.currentOrder.executors = executors;
  },
  // Мутация для обновления requests текущего заказа
  saveRequestOrder(state, {requestOrder,index}) {
    if (index !== -1) {
      state.currentOrder.requests.splice(index, 1, requestOrder);
    } else {
      state.currentOrder.requests.unshift(requestOrder);
    }
  },
  setUploaded(state, uploaded) {
    state.uploaded = uploaded;
  },
  setError(state, error) {
    state.error = error;
  },
  updateOrder(state, updatedOrder) {
    state.currentOrder = updatedOrder;
  },
  // Дополнительные мутации по необходимости
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};