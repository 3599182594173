// mutations.js в папке модуля websocket

export const setWsConnection = (state, connection) => {
  state.wsConnection = connection;
};

export const setIsConnected = (state, status) => {
  state.isConnected = status;
};



// export const setUnreadEmailsCount = (state, count) => {
//   state.unreadEmailsCount = count;
// };
//
// export const setUnreadMessageCount = (state, count) => {
//   console.log('setUnreadMessageCount:',count);
//   state.unreadMassageCount = count;
// };



