// store/modules/dashboardOrders.js
import orderAPI from "@/service/API/orderAPI";
import { getSettings } from "@/utils/localStorageUtils";

const state = {
    orders: [],
    count: 0,
    initOrders: true,
    loading: false,
    queryParams: {},
    initialized: false,
    error: null,
};
function parseQueryParams(queryParams) {
    const settings = {
        filterField: [],
        sortField: [],
        ...queryParams,

    };
    for (const key in queryParams) {
        if (key === 'ordering') {
            const sortFields = queryParams[key].split(',');
            sortFields.forEach(field => {
                const order = field.startsWith('-') ? -1 : 1;
                const fieldName = field.startsWith('-') ? field.slice(1) : field;
                settings.sortField.push({ field: fieldName, order: order });
            });
        } else if (key !== 'page' && key !== 'page_size' ) {
            const valueArray = queryParams[key].split(',');
            const isNumericArray = valueArray.every(item => !isNaN(item));
            settings.filterField.push({
                field: key,
                value: isNumericArray ? valueArray.map(Number) : valueArray
            });
        }
    }
    console.log('settings',settings);
    return settings;
}
const getters = {
    orders: (state) => state.orders,
    count: (state) => state.count,
    loading: (state) => state.loading,
    queryParams: (state) => state.queryParams,
    initialized: (state) => state.initialized,
    initOrders: (state) => state.initOrders,
    error: (state) => state.error,
    settingsOrders: (state)  => parseQueryParams(state.queryParams)
};

function createQueryParams(settings) {
    const params = {};
    // Пример значений по умолчанию
    params.page = 1;
    params.page_size = 10;

    if (settings.filterField) {
        settings.filterField.forEach(filter => {
            params[filter.field] = filter.value.join(',');
        });
    }
    if (settings.sortField) {
        if (settings.sortField.length) {
            params.ordering = settings.sortField.map(sort => {
                return `${sort.order === -1 ? '-' : ''}${sort.field}`;
            }).join(',');
        }
    }
    // Пример значения по умолчанию для kp
    return params;
}

const actions = {
  async fetchOrders({ commit, state }) {
    commit("setLoading", true);
    try {
      const response = await orderAPI.getOrderList(state.queryParams);
      commit("setOrders", response.data.results);
      commit("setCount", response.data.count);
      commit("setInitOrders", true);
    } catch (error) {
      console.error("Ошибка при получении заказов:", error);
      commit("setError", error);
    } finally {
      commit("setLoading", false);
    }
  },

  async initSettings({ commit}) {
    const settingsOrders = getSettings("dashboard", "orderBlock");
    const queryParamsOrders = settingsOrders
      ? createQueryParams(settingsOrders)
      : {};
    // const response = await orderAPI.getOrdersCount(queryParamsOrders);
    commit("setQueryParams",queryParamsOrders);
    // commit("setCount", response.data.total_count);
    commit("setInitialized", true);
    // await dispatch("updateOrdersCount");
    // await dispatch("fetchOrders");
  },

  async updateOrdersCount({ commit,state }) {
      console.log("updateOrdersCount");
      const response = await orderAPI.getOrdersCount(state.queryParams);
      commit("setCount", response.data.total_count);
      commit("setInitOrders", false);

  },
};

const mutations = {
  setOrders(state, orders) {
    state.orders = orders;
  },
  setInitOrders(state, initOrders) {
    state.initOrders = initOrders;
  },
  setCount(state, count) {
    state.count = count;
    state.initOrders = false;
  },
  setLoading(state, loading) {
    state.loading = loading;
  },
  setQueryParams(state, queryParams) {
    state.queryParams = queryParams;
  },
  setInitialized(state, initialized) {
    state.initialized = initialized;
  },
  setError(state, error) {
    state.error = error;
  },
};

// const subscriptions = (store) => {
//   store.subscribe((mutation) => {
//     if (mutation.type === "dashboardOrders/setQueryParams") {
//       store.dispatch("dashboardOrders/updateOrdersCount");
//     }
//   });
// };

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};