import API from "@/service/API/API";
const url_pref = '/api/v1'
export default {
    getOrderList(params = {})  {
        console.log('getOrderList')
        return API().get(`${url_pref}/order/`, { params })
    },
    getOrdersCount(params = {})  {
        console.log('getOrdersCount')
        return API().get(`${url_pref}/order/total_count/`, { params })
    },
    getOrderLogList(params = {})  {
        console.log('getOrderLogList')
        return API().get(`${url_pref}/order-log/`, { params })
    },

    getContractsHistory(orderId,params={}){
        console.log('getContractsHistory by Order')
        return API().get(`${url_pref}/order/${orderId}/contract-history/`, { params })
    },
    getOrderAggregateList(params = {})  {
        console.log('getOrderAggregateList')
        return API().get(`${url_pref}/order/aggregate/`, { params })
    },
    getOrderFields()  {
        console.log('getOrderFields')
        return API().get(`${url_pref}/order/fields/`)
    },
    deleteOrder(orderId)  {
        console.log('deleteOrder')
        return API().delete(`${url_pref}/order/${orderId}/`)
    },
    saveOrderRequest(data = {}){
        if (data.id) {
            return API().put(`${url_pref}/order-request/${data.id}/`, data)
                .then(response =>  response )
                .catch(error => { throw error.response });
        }else {
            return API().post(`${url_pref}/order-request/`,data)
                .then(response =>  response )
                .catch(error => { throw error.response });
        }
    },
    getOrderStatus(){
        console.log('getOrderList')
        return API().get(`${url_pref}/order-status/`)
    },
    getHistory(orderId,params={}){
        console.log('getHistory by Order')
        return API().get(`${url_pref}/order/${orderId}/history/`, { params })
    },
    getEvents(orderId,params={}){
        console.log('getEvents by Order')
        return API().get(`${url_pref}/order/${orderId}/events/`, { params })
    },
    getChats(orderId){
        console.log('getChats')
        return API().get(`${url_pref}/order/${orderId}/chats/` )
    },

    getContracts(orderId){
        console.log('getContracts by Order')
        return API().get(`${url_pref}/order/${orderId}/contracts/`)
    },
    getFiles(orderId,params={}){
        console.log('getFiles by Order')
        return API().get(`${url_pref}/order/${orderId}/files/`,{ params })
    },
    getMessages(orderId, params={}){
        console.log('getMessages by Order',orderId, params)
        return API().get(`${url_pref}/order/${orderId}/messages/`,{ params })
    },
    getMessagesByFeed(orderId, params={}){
        console.log('getMessages by Order',orderId, params)
        return API().get(`${url_pref}/order/${orderId}/messages_feed/`,{ params })
    },
    getCompany(orderId){
        console.log('getCompany by Order')
        return API().get(`${url_pref}/order/${orderId}/company/`)
    },
    getOrder(orderId){
        console.log('getOrder')
        return API().get(`${url_pref}/order/${orderId}/`)
    },
    toggleExecutor(orderId){
        console.log('toggleExecutor')
        return API().get(`${url_pref}/order/${orderId}/toggle-executor/`)
    },
    addOrder(data){
        return API().post(`${url_pref}/order/`,data)
    },
    editOrder(order_id,data){
        return API().patch(`${url_pref}/order/${order_id}/`,data)
    },

}