
export const setUnreadEmailsCount = (state, count) => {
  state.unreadEmailsCount = count;
};

export const initializeUnreadMessageListState = (state,stateLoading ) => {
  state.initializeUnreadMessageListState = stateLoading;
};
export const setUnreadMessageList = (state,unreadMessageList ) => {
  state.unreadMessageList = unreadMessageList;
};
export const setUnreadMessageCount = (state,count ) => {
  state.unreadMessageCount = count;
};

export const addUnreadMessage = (state, message) => {
  state.unreadMessageList.push(message);
  state.unreadMessageCount++;
};

export const updateUnreadMessage = (state, message) => {
  // Получаем массив сообщений
  const messages = state.unreadMessageList;

  // Ищем индекс сообщения с таким же id
  const messageIndex = messages.findIndex(msg => msg.id === message.id);

  if (messageIndex !== -1) {
    // Заменяем существующее сообщение
    messages[messageIndex] = message;
  }
};
export const setNotification = (state,notification ) => {
  state.notification = notification;
    console.log('setNotification:',state.notification);
};

export const updateUnreadMessageList = (state, { orderId, chatName }) => {
  state.unreadMessageList = state.unreadMessageList.filter(message => {
      const messageOrderId = message.chat.order;
      const messageChatName = message.chat_type === 'personal' ? message.creator : message.chat.chat_type;
      return !(messageOrderId === orderId && messageChatName === chatName);
    });
};

export const removeNotifications = (state ) => {
  state.notifications = null;
};




