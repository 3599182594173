// store/modules/orderFiles.js
import orderAPI from "@/service/API/orderAPI";
import uploadAPI from "@/service/API/uploadAPI";

const state = {
  CurrentOrderId: null, // ID текущего заказа или null
  files: [],
  uploaded: false,
  error: null,
};

const getters = {
  // Геттер для получения текущего заказа
  currentOrder: (state) => state.CurrentOrderId,
  uploaded: (state) => state.uploaded,
  files: (state) => state.files,
  error: (state) => state.error,
  // Геттеры для других свойств по необходимости
};

const actions = {
  async fetchFiles({ commit }, orderId) {
    try {
      commit('setCurrentOrderId', orderId);
      const response = await orderAPI.getFiles(orderId);
      commit('setFiles', response.data); // Обновляем файлы
      commit('setUploaded', true);
      commit('setError', null);
    } catch (error) {
      console.error('Ошибка при получении контрактов:', error);
      commit('setError', error);
    }
  },
  async deleteFile({ commit }, fileId) {
    try {
      const response = await uploadAPI.deleteFile(fileId);
      console.log(response);
      const index = state.files.findIndex((file) => file.id === fileId);
      if (index > -1) {
        commit('deleteFile', index); // Удаляем файл из списка
      }
      return {success:true,detail:response.data}
    } catch (error) {
      console.error('Ошибка при удаление файла:', error);
      commit('setError', error);
      return {success:false,detail:error}
    }
  },
  loadFile({ commit }, file) {
    commit('addFile', file); // Обновляем файлы
  },
  async resetOrderStore({ commit }) {
    commit('setCurrentOrderId', null);
    commit('setFiles', []);
    commit('setUploaded', false);
    commit('setError', null);
    // Сброс других частей состояния по необходимости
  }
  // Дополнительные действия по необходимости
};

const mutations = {
  // Мутация для обновления текущего заказа
  setCurrentOrderId(state, orderId) {
    state.currentOrder = orderId; // Устанавливаем текущий заказ
  },
  // Мутация для обновления files
  setFiles(state, files) {
    state.files = files; // Устанавливаем files
  },
  // Мутация для добавления в files
  addFile(state, file) {
    state.files.push(file);
  },
  // Мутация для удаления file
  deleteFile(state, index) {
    state.files.splice(index, 1);
  },
  setUploaded(state, uploaded) {
    state.uploaded = uploaded;
  },
  setError(state, error) {
    state.error = error;
  },
  // Дополнительные мутации по необходимости
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};