// store/modules/notifications/getters.js


export const unreadMessageList  = (state) => {
  return state.unreadMessageList;
};

export const unreadMessageCount  = (state) => {
  return state.unreadMessageCount;
};
export const initializeUnreadMessageListState  = (state) => {
  return state.initializeUnreadMessageListState;
};
export const notification  = (state) => {
  return state.notification;
};
