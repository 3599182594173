export function handleMessages(commit, data) {
    switch (data.action) {
        case 'users_list':
            commit('updateAllUsers', data.data); // Обновление списка всех пользователей
            break;
        case 'user_status_change':
            commit('updateUser', data.data);
            break;
        case 'user_print_change':
            commit('updateUserPrint', data.data);
            break;
    }
}