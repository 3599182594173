// store/modules/orderEvents.js
import orderAPI from "@/service/API/orderAPI";
import eventAPI from "@/service/API/eventAPI";

const state = {
  events: [],
  uploaded: false,
  error: null,
};

const getters = {
  // Геттер для получения текущего заказа
  uploaded: (state) => state.uploaded,
  events: (state) => state.events,
  error: (state) => state.error,
  // Геттеры для других свойств по необходимости
};

const actions = {
  async fetchEvents({ commit }, orderId) {
    try {
      const response = await orderAPI.getEvents(orderId);
      commit('setEvents', response.data); // Обновляем файлы
      commit('setUploaded', true);
      commit('setError', null);
    } catch (error) {
      console.error('Ошибка при получении напоминаний:', error);
      commit('setError', error);
    }
  },
  async saveEvent({ commit,state },data) {
    try {
      const index = state.events.findIndex(event => event.id === data.id);
      if (index !== -1) {
        commit('updateEventAtIndex', { index, data: data });
      } else {
        commit('addEvent', data);
      }
    } catch (error) {
      console.error('Ошибка при сохранении события:', error);
      commit('setError', error);
      return {success:false,detail:error}
    }
  },
  async deleteEvent({ commit,state },eventId) {
    try {
      const response = await eventAPI.deleteEvent(eventId);
      const index = state.events.findIndex(event => event.id === eventId);
      if (index !== -1) {
        commit('deleteEvent', index);
      }
      return {success:true,detail:response.data}
    } catch (error) {
      console.error('Ошибка при удалении события:', error);
      commit('setError', error);
      return {success:false,detail:error}
    }
  },
  async resetOrderStore({ commit }) {
    commit('setEvents', []);
    commit('setUploaded', false);
    commit('setError', null);
    // Сброс других частей состояния по необходимости
  }
  // Дополнительные действия по необходимости
};

const mutations = {
  // Мутация для обновления files
  setEvents(state, events) {
    state.events = events; // Устанавливаем files
  },
  // Мутация для добавления в files
  updateEventAtIndex(state,  { index, data }) {
    // Замена старого события на новое в массиве events по индексу
    state.events.splice(index, 1, data);
  },
  // Мутация для добавления в event
  addEvent(state, event) {
    state.events.push(event);
  },
  // Мутация для удаления в event
  deleteEvent(state, index) {
    state.events.splice(index, 1);
  },
  setUploaded(state, uploaded) {
    state.uploaded = uploaded;
  },
  setError(state, error) {
    state.error = error;
  },
  // Дополнительные мутации по необходимости
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};