// import router from "@/router";


export async function handleMessages({commit, dispatch}, data) {
    switch (data.scope_type) {
        case 'order_chat': {
            switch (data.action) {
                case 'add_message': {
                    const message = data.message;
                    console.log('order_chat', message);
                    commit('addUnreadMessage', message);
                    break;
                }
                case 'update_message': {
                    const message = data.message;
                    console.log('order_chat', message);
                    commit('updateUnreadMessage', message);
                    break;
                }
            }
            break;
        }

        case 'order': {
            switch (data.action) {
                case 'update': {
                    const message = data.message;
                    console.log('order_update', message);
                    await dispatch('dashboardOrders/updateOrdersCount', null, { root: true });
                    await dispatch('dashboardFeed/fetchFeed', null, { root: true });
                    break;
                }
            }
            break;
        }

        case 'contract': {
            switch (data.action) {
                case 'update': {
                    const message = data.message;
                    console.log('order_update', message);
                    await dispatch('dashboardContracts/updateContractsCount', null, { root: true });
                    break;
                }
            }
            break;
        }
    }
}