//websocket/actions.js

import { getWebSocketUrl } from './helpers';
import ReconnectingWebSocket from "reconnecting-websocket";

export const initializeWebSocket = ({ commit, state,dispatch  }) => {
    console.log("initializeWebSocket");
  if (!state.wsConnection) {

    const wsBaseUrl = getWebSocketUrl();
    const options = {
        reconnectInterval: 1000,
        maxReconnectInterval: 30000,
        reconnectDecay: 1.5,
        maxReconnectAttempts: null,
        automaticOpen: true
    };
    const newWsConnection = new ReconnectingWebSocket(wsBaseUrl, [], options);
    newWsConnection.onopen = () => {
        console.log("WebSocket connection opened");
        commit('setIsConnected', true);
        // Дополнительные действия при открытии соединения
    };
    newWsConnection.onmessage = (event) => {
        const data = JSON.parse(event.data);
        switch (data.type)
        {
          case 'notifications':
            dispatch('notifications/handleNotificationsMessages', data, { root: true });
            break;
          case 'update':
              switch (data.scope_type){
                  case 'users':
                      dispatch('users/handleUsersMessages', data, { root: true });
                      break;
                  default:
                      console.log('Unknown scope_type:', data.scope_type);
              }
            break;
          case 'chat':
              switch (data.scope_type){
                  case 'order_chat':
                      dispatch('orderChat/handleChatMessages', data, { root: true });
                      break;
                  case 'global_chat':
                      dispatch('globalChat/handleChatMessages', data, { root: true });
                      break;
                  default:
                      console.log('Unknown scope_type:', data.scope_type);
              }
              break;
          default:
              console.log('Unknown message type:', data.type);
        }

    };
    newWsConnection.onerror = (error) => {
        console.error("WebSocket error occurred:", error);
    };
    newWsConnection.onclose = (event) => {
        console.log(event);
        console.log('WebSocket connection closed');
        commit('setIsConnected', false);
        // Дополнительные действия при закрытии соединения
    };
    commit('setWsConnection', newWsConnection);
  }
};
export const disconnectWebSocket = ({ commit, state }) => {
  if (state.wsConnection) {
    state.wsConnection.close();
    commit('setWsConnection', null);
    commit('setIsConnected', false);
    console.log('WebSocket connection manually closed');
  }
};
export const sendMessage = ({ state },message) => {
    if (state.wsConnection && state.wsConnection.readyState === WebSocket.OPEN) {
      state.wsConnection.send(JSON.stringify(message));
    } else {
      console.error("WebSocket connection is not open. Failed to send message:", message);
    }
}
