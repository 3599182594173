// store/modules/orderChat/getters.js

export const initializeChatsState = (state) => {
  return state.initializeChatsState;
};
export const loadingChatState = (state) => {
  return state.loadingChatState;
};
export const currentOrderId = (state) => {
  return state.currentOrderId;
};
export const unreadMessagesCount = (state) => {
  return Object.values(state.chats).reduce((total, chat) => total + chat.unread_messages, 0);
};
export const chats = (state) => {
  return state.chats;
};
export const selectedChat = (state) => {
  return state.selectedChat;
};
