export const updateAllUsers = (state, users) => {
  state.allUsers = users;
};
export const updateUser = (state, data) => {
  state.allUsers[data.id] = data
  if (data.is_online === false) {
    state.printUsers[data.id] = null
  }
};

export const updateUserPrint = (state, data) => {
  Object.keys(data).forEach(key => {
    state.printUsers[key] = data[key];
  });
};




