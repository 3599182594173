import API from "@/service/API/API";
const url_pref = '/api/v1'
export default {
    getContract(contractId){
        console.log('getContract')
        return API().get(`${url_pref}/contract/${contractId}`)
    },
    getContractsList(params = {})  {
        return API().get(`${url_pref}/contract/`, { params })
    },
    getContractsCount(params = {})  {
        return API().get(`${url_pref}/contract/total_count/`, { params })
    },
    // getContracts(contractId)  {
    //     return API().get(`${url_pref}/contract/${contractId}`, )
    // },
    getHistory(contractId,params={}){
        console.log('getHistory by Order')
        return API().get(`${url_pref}/contract/${contractId}/history/`, { params })
    },
    getDateProduction(date,day){
        return API().get(`${url_pref}/date-production/?date=${date}&day=${day}`)
    },
    getContractFields()  {
        console.log('getContractFields')
        return API().get(`${url_pref}/contract/fields/`)
    },
    getContractCalendar()  {
        console.log('getContractCalendar')
        return API().get(`${url_pref}/contract/calendar/`)
    },
    getStatusList(){
        return API().get(`${url_pref}/contract-status/`)
    },
    getListGK(){
        return API().get(`${url_pref}/group-company/`)
    },
    getListPayer(){
        return API().get(`${url_pref}/payer/`)
    },
    deleteContract(id){
        return API().delete(`${url_pref}/contract/${id}/`)
    },
    saveContract(data){
        if (data.id) {
            // Если у контракта есть идентификатор, выполняем запрос на обновление (PATCH)
            return API().patch(`${url_pref}/contract/${data.id}/`, data);
        } else {
            // Если у контракта нет идентификатора, выполняем запрос на добавление (POST)
            return API().post(`${url_pref}/contract/`, data);
        }
    },
}