import { computed } from 'vue';
import { useStore } from 'vuex';

export function useWebSocket() {
  const store = useStore();

  // Состояние соединения
  const isConnected = computed(() => store.getters['websocket/isConnected']);
  const unreadEmailsCount = computed(() => store.state.notifications.unreadEmailsCount);
  // const unreadMessageList = computed(() => store.state.notifications.unreadMessageList);
  const allUsers = computed(() => store.getters['users/allUsers']);
  const printUsers = computed(() => store.getters['users/printUsers']);
  const notification = computed(() => store.state.notifications.notification);
  const usersList = computed(() => {
    return Object.entries(allUsers.value).map(([id, userInfo]) => {
      return {
        id,
        ...userInfo
      };
    });
  });
  // Методы для управления WebSocket соединением
  const initializeWebSocket = async () => {
    await store.dispatch('websocket/initializeWebSocket');
  };

  const disconnectWebSocket = async () => {
    await store.dispatch('websocket/disconnectWebSocket');
  };

  // Подписка и отписка от событий
  const subscribe = async (callback, scopeType) => {
    await store.dispatch('websocket/subscribe', { callback, scopeType });
  };
  // const removeNotification = async () => {
  //   await store.dispatch('notifications/removeNotifications' );
  // };
  const handleIncomingMessage = (data) => {
    // Тут ваша логика обработки сообщений
    console.log('handleIncomingMessage:',data)
  };
  const unsubscribe = async (callback) => {
    await store.dispatch('websocket/unsubscribe', callback);
  };

  return {
    isConnected,
    notification,
    // removeNotification,
    unreadEmailsCount,
    // unreadMessageList,
    allUsers,
    printUsers,
    usersList,
    handleIncomingMessage,
    disconnectWebSocket,
    initializeWebSocket,
    subscribe,
    unsubscribe,
  };
}