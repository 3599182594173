 // handlers.js

import {addMessage, addMessages, updateMessage,} from "@/utils/orderChat";

export function handleGlobalMessages(commit, rootGetters, data,state) {
    const message = data.message
    const chat_name = message.chat_name
    // Обработка сообщений чата
    switch (data.action) {
        case 'add_message': {
            console.log('add_message')
            const userId = rootGetters['auth/user'].id;
            const chats = rootGetters['globalChat/chats'];
            const chat_message = message.chat_message
            console.log('add_message',userId,chat_name,chat_message,!chats[chat_name])
            if (!chats[chat_name]){
                let interlocutor
                let chat_type
                if (/^\d+$/.test(chat_name)) {
                    interlocutor = parseInt(chat_name);
                    chat_type = 'personal';
                } else {
                    interlocutor = null;
                    chat_type = chat_name;
                }
                const chat = {
                      id: chat_message.chat,
                      interlocutor: interlocutor,
                      last_message: chat_message,
                      last_read_message_id: null,
                      chat_type: chat_type,
                      unread_messages: 1,
                }
                commit('addChat', {chat_name, chat})

                const messages = addMessages([], [chat_message], userId)
                commit('setMessages', {chat_name, messages})
            }else{
                const oldMessages = chats[chat_name]['messages']
                if (oldMessages !== undefined){
                    const messages = addMessage(oldMessages, chat_message, userId)
                    commit('setMessages', {chat_name, messages})
                }
                console.log(state.chats)
                commit('addChatLastMessage', {chat_name, chat_message})
            }
            break;
        }
        case 'update_last_read_message': {
            const last_read_message_id = message.last_read_message_id;
            commit('setLastReadMessage', {chat_name, last_read_message_id});
            break;
        }
        case 'update_message': {
            const chats = rootGetters['globalChat/chats'];
            const chat_message = message.chat_message
            if ('messages' in chats[chat_name]){
                const messages = updateMessage(chats[chat_name]['messages'], chat_message)
                commit('setMessages', {chat_name, messages})
            }
            if (chats[chat_name]?.last_message?.id === chat_message.id){
                commit('updateChatLastMessage', {chat_name, chat_message})
            }
            break;
        }
    }
}