// store/modules/eventsType.js
import eventAPI from "@/service/API/eventAPI";

const state = {
  eventsTypeList: [],
  uploaded: false,
  error: null,
};

const getters = {
  eventsTypeList: state => state.eventsTypeList,
  uploaded: state => state.uploaded,
  error: state => state.error,
};

const actions = {
  async fetchEventsTypeList({ commit }) {
    try {
      const cachedEventsType = localStorage.getItem('eventsTypeList');
      if (cachedEventsType) {
        commit('setEventsTypeList', JSON.parse(cachedEventsType));
        commit('setError', null);
      } else {
        const response = await eventAPI.getEventsTypeList();
        commit('setEventsTypeList', response.data);
        localStorage.setItem('eventsTypeList', JSON.stringify(response.data));
        commit('setError', null);
      }
    } catch (error) {
      console.error('Ошибка при получении eventsTypeList:', error);
      commit('setError', error);
    } finally {
      commit('setUploaded', true);
    }
  },
  async saveEventType({ commit,state },data) {
    try {
      console.log('saveEventType',data)
      const index = state.eventsTypeList.findIndex(type => type.id === data.id);
      if (index !== -1) {
        commit('updateTypeAtIndex', { index, data: data });
      } else {
        commit('addType', data);
      }
    } catch (error) {
      console.error('Ошибка при обновлении типа события:', error);
      commit('setError', error);
    }
  },
};

const mutations = {
  setEventsTypeList(state, eventsTypeList) {
    state.eventsTypeList = eventsTypeList;
  },
  setUploaded(state, uploaded) {
    state.uploaded = uploaded;
  },
  updateTypeAtIndex(state, {index, data}) {
    state.eventsTypeList[index] = data;
  },
  addType(state, data) {
    state.eventsTypeList.push(data);
  },
  setError(state, error) {
    state.error = error;
  },
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};