import API from "@/service/API/API";
const url_pref = '/api/v1'
export default {
    getPersonsList(){
        console.log('getPersonsList')
        return API().get(`${url_pref}/person/`)
    },
    getPositionsList(){
        console.log('getPersonsList')
        return API().get(`${url_pref}/position/`)
    },
    getPersons(id){
        return API().get(`${url_pref}/person/${id}/`)
    },
    savePosition(data){
        if (data.id) {
            return API().put(`${url_pref}/position/${data.id}/`, data)
                .then(response =>  response )
                .catch(error => { throw error.response });
        }else {
            return API().post(`${url_pref}/position/`,data)
                .then(response =>  response )
                .catch(error => { throw error.response });
        }
    },
    savePerson(data){
        if (data.id) {
            return API().put(`${url_pref}/person/${data.id}/`, data)
                .then(response =>  response )
                .catch(error => { throw error.response });
        }else {
            return API().post(`${url_pref}/person/`,data)
                .then(response =>  response )
                .catch(error => { throw error.response });
        }
    },
    getMyPerson(){
        console.log('getMyPerson')
        return API().get(`${url_pref}/person/my_user/`)
    },

}